<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="30%"
    @close="handleClose"
  >
    <el-form ref="formRef" :model="form" label-width="150px" :rules="rules">

      <el-form-item label="病人姓名" prop="name">
        <el-input v-model="form.name" style="width: 200px"></el-input>
      </el-form-item>

      <el-form-item label="病人身份证号码" prop="idNumber">
        <el-input v-model="form.idNumber" style="width: 200px"></el-input>
      </el-form-item>

      <el-form-item label="病人手机号码" prop="telephone">
        <el-input v-model="form.telephone" style="width: 200px"></el-input>
      </el-form-item>

      <el-form-item label="病人性别" prop="sex">
        <el-radio v-model="form.sex" label="男">男</el-radio>
        <el-radio v-model="form.sex" label="女">女</el-radio>
<!--        <el-radio-group v-model="form.sex">-->
<!--          <el-radio :label="0">男</el-radio>-->
<!--          <el-radio :label="1">女</el-radio>-->
<!--        </el-radio-group>-->
      </el-form-item>

      <el-form-item label="转诊目的" prop="intent">
        <el-select v-model="form.intent" placeholder="请选择转诊目的" style="width:200px">
          <el-option
            v-for="item in range_intent"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="预约市级医院" prop="hospital">
        <el-select v-model="form.hospital" placeholder="请选择市级医院" style="width:300px">
          <el-option
            v-for="item in range_hospital"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>

<!--      <el-form-item label="预约挂号科室" prop="departName">-->
<!--        <el-select v-model="form.departName" placeholder="请选择科室" style="width:300px">-->
<!--          <el-option-->
<!--            v-for="item in range_department"-->
<!--            :key="item"-->
<!--            :label="item"-->
<!--            :value="item">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="选择终审科室" prop="endDepartment">-->
<!--        <el-select v-model="form.endDepartment" placeholder="请选择科室" style="width:300px">-->
<!--          <el-option-->
<!--            v-for="item in range_department"-->
<!--            :key="item"-->
<!--            :label="item"-->
<!--            :value="item">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

      <el-form-item label="预计到达时间" prop="arriveTime">
        <el-date-picker
          v-model="form.arriveTime"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>



<!--      <el-form-item label="申请积分" prop="integral">-->
<!--        <el-input v-model="form.integral" style="width: 200px"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="核定积分" prop="applicationIntergral">-->
<!--        <el-input v-model="form.applicationIntergral" style="width: 200px"></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="病人病情描述" prop="patientDisease">
        <el-input
          v-model="form.patientDisease"
          :rows="4"
          type="textarea"
          style="width:300px"
        />
      </el-form-item>

<!--      <el-form-item label="商品介绍" >-->
<!--&lt;!&ndash;        <el-input-->
<!--          v-model="form.productIntroImgs"-->
<!--          :rows="4"-->
<!--          type="textarea"-->
<!--        />&ndash;&gt;-->
<!--      </el-form-item>-->

<!--      <QuillEditor-->
<!--        v-model:content="form.productIntroImgs"-->
<!--        contentType="html"-->
<!--        toolbar="full"-->
<!--        theme="snow"-->
<!--        style="height:200px"-->
<!--      />-->

<!--      <br/>-->
<!--      <br/>-->
<!--      <el-form-item label="商品参数" >-->
<!--&lt;!&ndash;        <el-input-->
<!--          v-model="form.productParaImgs"-->
<!--          :rows="4"-->
<!--          type="textarea"-->
<!--        />&ndash;&gt;-->
<!--      </el-form-item>-->

<!--      <QuillEditor-->
<!--        v-model:content="form.productParaImgs"-->
<!--        contentType="html"-->
<!--        toolbar="full"-->
<!--        theme="snow"-->
<!--        style="height:200px"-->
<!--      />-->


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits,ref ,defineProps,watch} from 'vue'
import axios from "@/util/axios";
import { ElMessage } from "element-plus";


const range_intent=ref([
  '选择转诊目的','明确诊断','进一步治疗','辅助检查','患者要求','其他'
])
const range_department=ref([
  '选择预约挂号科室','急诊科','重症医学科','外一科（普外、肛肠等方向）','外二科（泌外、男科等方向）','骨伤一科（脊柱、脑外、创伤等方向）','骨伤二科（关节、胸外、创伤等方向）','妇产科','眼耳鼻喉科','手术室','口腔科','内一科（心病方向）','内二科（肝病、脾胃、肿瘤等方向）','内三科（脑病方向）','内五科（肺病、老年病等方向）','内六科（肾病、内分泌、风湿等方向）','针灸康复科','儿科','治未病科','门诊','体检科','药剂科','医学检验科','功能科','内镜室','高压氧','放射科','CT室','供应室','120中心','新院导诊','轮科'
])
const range_hospital=ref([
  '选择预约市级医院','赣南医学院第一附属医院','赣州市肿瘤医院',
])


const props=defineProps({
  dialogTitle:{
    type:String,
    default:'',
    required:true
  },
  dialogValue:{
    type:Object,
    default:()=>{}
  }
})

const bigTypeId=ref("")

const form=ref({
  reportId:'',
  id:-1,
  name:'',
  idNumber:'',
  telephone:'',
  sex:'',
  intent:'',
  departName:'',
  arriveTime:'',
  integral:'',
  applicationIntergral:'',
  patientDisease:'',


  price:0,
  stock:0,
  type:{
    id:''
  },
  remark:'',
  productIntroImgs:'',
  productParaImgs:''
})



const emits=defineEmits(['update:modelValue','initProductList'])

const formRef=ref(null)


const handleClose = () => {
  formRef.value.resetFields();
  emits('update:modelValue',false)
}



const rules=ref({
  name:[
    { required: true, message: '请输入病人姓名！', }
  ],
  telephone:[
    { required: true, message: '请输入病人手机号码！', }
  ],
  intent:[
    { required: true, message: '请选择转诊目的！', }
  ],
  departmentName:[
    { required: true, message: '请选择科室！', }
  ],
  arriveTime:[
    { required: true, message: '请选择预计到达时间！', }
  ],


  price:[
    { required: true, message: '请输入商品价格!' },
    { type: 'number', message: '商品价格是数值类型！',transform: (value) => Number(value) },
  ],

})

const bigTypeSlectOptions =ref([])

const smallTypeSlectOptions =ref([])


const initBigTypeSelectList=async()=>{
  console.log('xxx')
  const res=await axios.post("admin/bigType/listAll");
  bigTypeSlectOptions.value=res.data.bigTypeList;
}

const initSmallTypeSelectList=async(bigTypeId)=>{
  console.log('bigTypeId='+bigTypeId)
  const res=await axios.post("admin/smallType/listAll/"+bigTypeId);
  smallTypeSlectOptions.value=res.data.smallTypeList;
}


initBigTypeSelectList();

const handleConfirm = () => {

  // if(form.value.type.id==""){
  //   ElMessage.error("请选择商品类别");
  //   return;
  // }
  formRef.value.validate(async (valid) => {
    console.log("dadsadasdasdsad")
    console.log(valid)
    if (valid) {
      console.log("xxx")
       try{
        let result=await axios.post("report-info/update",form.value);
         console.log("12xxx"+result)
        let data=result.data;
        if(data.code==0){
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          bigTypeId.value=""
          emits("initProductList")
          handleClose();
        }else{
          ElMessage.error(data.msg);
        }
      }catch(err){
        console.log("error:"+err)
        ElMessage.error('系统运行出错，请联系管理员');
      }
    } else {
      return false
    }
  })
}

const handleBigTypeChange = (val) => {
  console.log("val="+val)
  initSmallTypeSelectList(val);
}

const getBigTypeIdBySmallTypeId=async (smallTypeId)=>{
  console.log('xxx')
  const res=await axios.get("admin/smallType/getBigTypeIdBySmallTypeId/"+smallTypeId);
  console.log(res.data.bigTypeId)
  handleBigTypeChange(res.data.bigTypeId)
  //return res.data.bigTypeId;
}


watch(
  ()=>props.dialogValue,
  ()=>{
    console.log("dialogValue222="+props.dialogValue);
    console.log(props.dialogValue)
    form.value=props.dialogValue;
    console.log(form.value.id)
    console.log(form.value.productParaImgs)
    if(form.value.id==undefined){
      console.log("是添加")
    }else{
      console.log("是修改")
      //bigTypeId.value=2;
      getBigTypeIdBySmallTypeId(form.value.type.id);
    }
  },
  {deep:true,immediate:true}
)

</script>

<style scoped>

</style>